const AdminSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Persons',
    name: 'sidebar.admin.persons',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-user-line',
    children: [
      {
        title: 'Users',
        is_active: false,
        link: {
          name: 'admin.users'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Accounts',
        is_active: false,
        link: {
          name: 'admin.user-accounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Lead Agents',
        is_active: false,
        link: {
          name: 'admin.lead-agents'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-user-voice-line'
      },
      {
        title: 'Dealers',
        is_active: false,
        link: {
          name: 'admin.dealers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Customers',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.customers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-account-circle-line'
      },
      {
        title: 'Raw Data',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.raw-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-database-2-line'
      }
    ]
  },
  {
    title: 'Utilities',
    name: 'sidebar.admin.utilities',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-line',
    children: [
      {
        title: 'SMS',
        is_active: false,
        link: {
          name: 'admin.sms'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-mail-send-line'
      }
    ]
  },
  {
    title: 'Places',
    name: 'sidebar.admin.places',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Regions',
        is_active: false,
        link: {
          name: 'admin.regions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Provinces',
        is_active: false,
        link: {
          name: 'admin.provinces'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Municipalities',
        is_active: false,
        link: {
          name: 'admin.municipalities'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Barangays',
        is_active: false,
        link: {
          name: 'admin.barangays'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Distributions',
        is_active: false,
        link: {
          name: 'admin.distributions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      }
    ]
  },
  {
    title: 'Maintenances',
    name: 'sidebar.admin.maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Mobile Prefixes',
        is_active: false,
        link: {
          name: 'admin.mobile-prefixes'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-chat-settings-line'
      },
      {
        title: 'Dialects',
        is_active: false,
        link: {
          name: 'admin.dialects'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-chat-voice-line'
      },
      {
        title: 'Sources',
        is_active: false,
        link: {
          name: 'admin.sources'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-chat-voice-line'
      },
      {
        title: 'Occupations',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.occupations'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-4-line'
      },
      {
        title: 'Visitation Purposes',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.visitation-purposes'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-4-line'
      },
      {
        title: 'Survey Questionares',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.survey-questionares'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-4-line'
      },
      {
        title: 'Lead Tags',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.leadtags'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-4-line'
      },
      {
        title: 'Live Stocks',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.livestocks'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-4-line'
      },
      {
        title: 'Live Stock Types',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.livestocktypes'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-4-line'
      },
      {
        title: 'Live Stock Categories',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.livestockcategories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-4-line'
      },
      {
        title: 'Feed Brands',
        is_heading: false,
        is_active: false,
        link: {
          name: 'admin.feedbrands'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-home-4-line'
      }
    ]
  }
]

export default AdminSideMenu
