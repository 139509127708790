const SupervisorSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'supervisor.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Raw Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'supervisor.raw-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-database-2-line'
  },
  {
    title: 'Campaign Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'supervisor.campaign-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-flag-line'
  },
  {
    title: 'Master Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'supervisor.master-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-group-line'
  },
  {
    title: 'Maintenances',
    name: 'sidebar.supervisor.maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Disposition Types',
        is_active: false,
        link: {
          name: 'supervisor.disposition-types'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-folder-unknow-line'
      },
      {
        title: 'Dispositions',
        is_active: false,
        link: {
          name: 'supervisor.dispositions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-unknow-line'
      },
      {
        title: 'Unsuccessful Reasons',
        is_active: false,
        link: {
          name: 'supervisor.unsuccessful-reasons'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-error-warning-line'
      },
      {
        title: 'Promotions',
        is_active: false,
        link: {
          name: 'supervisor.promotions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-user-voice-line'
      },
      {
        title: 'Campaigns',
        is_active: false,
        link: {
          name: 'supervisor.campaigns'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Custom Fields',
        is_active: false,
        link: {
          name: 'supervisor.custom-fields'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-input-method-line'
      },
      {
        title: 'Agent Groups',
        is_active: false,
        link: {
          name: 'supervisor.agent-groups'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-folder-user-line'
      },
      {
        title: 'Agents',
        is_active: false,
        link: {
          name: 'supervisor.agents'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-user-voice-line'
      }
    ]
  },
  {
    title: 'Reports',
    name: 'sidebar.supervisor.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Call Logs',
        is_active: false,
        link: {
          name: 'supervisor.report-call-logs'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Call Summaries',
        is_active: false,
        link: {
          name: 'supervisor.report-call-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Connected Summaries',
        is_active: false,
        link: {
          name: 'supervisor.report-connected-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Unsuccessful Summaries',
        is_active: false,
        link: {
          name: 'supervisor.report-unsuccessful-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Promotion Summaries',
        is_active: false,
        link: {
          name: 'supervisor.report-promotion-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'SMS Summaries',
        is_active: false,
        link: {
          name: 'supervisor.report-sms-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Satisfaction Surveys',
        is_active: false,
        link: {
          name: 'supervisor.report-satisfaction-surveys'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  },
  {
    title: 'Statistics',
    name: 'sidebar.supervisor.statistics',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'General Information',
        is_active: false,
        link: {
          name: 'supervisor.statistics-general-information'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      }
    ]
  }
]

export default SupervisorSideMenu
