const UserSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Pending Visitations',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.pending-visitations'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-walk-line'
  },
  {
    title: 'Raw Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.raw-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-database-line'
  },
  {
    title: 'Facebook Raw Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.facebook-raw-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-facebook-box-line'
  },
  {
    title: 'My Leads',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.my-leads'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-user-line'
  },
  {
    title: 'My Call Requests',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.call-requests'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-phone-line'
  },
  {
    title: 'Reports',
    name: 'sidebar.user.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Visit Histories',
        is_active: false,
        link: {
          name: 'user.report-visit-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Call Request Histories',
        is_active: false,
        link: {
          name: 'user.report-call-request-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  }
]

export default UserSideMenu
