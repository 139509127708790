
const AgentSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'agent.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Raisers',
    name: 'sidebar.agent.raisers',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-database-line',
    children: [
      {
        title: 'Pending Calls',
        is_active: false,
        link: {
          name: 'agent.pending-calls'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-phone-line'
      },
      {
        title: 'Pending Data',
        is_active: false,
        link: {
          name: 'agent.pending-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-database-2-line'
      },
      {
        title: 'Invalid Pending Data',
        is_active: false,
        link: {
          name: 'agent.invalid-pending-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-database-2-line'
      },
      {
        title: 'Campaign Tracker',
        is_active: false,
        link: {
          name: 'agent.campaign-tracker'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-time-line'
      },
      {
        title: 'Campaign Data',
        is_active: false,
        link: {
          name: 'agent.campaign-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-flag-line'
      },
      {
        title: 'Master Data',
        is_active: false,
        link: {
          name: 'agent.master-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-group-line'
      },
      {
        title: 'Visit Requests',
        is_active: false,
        link: {
          name: 'agent.visit-requests'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-car-line'
      }
    ]
  },
  {
    title: 'Dealers',
    name: 'sidebar.agent.dealers',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-database-line',
    children: [
      {
        title: 'Master Data',
        is_active: false,
        link: {
          name: 'agent.dealers-master-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-group-line'
      }
    ]
  },
  {
    title: 'Facebook',
    name: 'sidebar.agent.facebook',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-facebook-circle-line',
    children: [
      {
        title: 'Pending Data',
        is_active: false,
        link: {
          name: 'agent.facebook-pending-data'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-group-line'
      },
      {
        title: 'Leads',
        is_active: false,
        link: {
          name: 'agent.facebook-leads'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-group-line'
      }
    ]
  },
  {
    title: 'Utilities',
    name: 'sidebar.agent.utilities',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-settings-line',
    children: [
      {
        title: 'SMS',
        is_active: false,
        link: {
          name: 'agent.sms'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-mail-send-line'
      }
    ]
  },
  {
    title: 'Reports',
    name: 'sidebar.agent.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Call Logs',
        is_active: false,
        link: {
          name: 'agent.report-call-logs'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Connected Summaries',
        is_active: false,
        link: {
          name: 'agent.report-connected-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Unsuccessful Summaries',
        is_active: false,
        link: {
          name: 'agent.report-unsuccessful-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Promotion Summaries',
        is_active: false,
        link: {
          name: 'agent.report-promotion-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Visit Request Histories',
        is_active: false,
        link: {
          name: 'agent.report-visit-request-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Call Request Histories',
        is_active: false,
        link: {
          name: 'agent.report-call-request-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'SMS Summaries',
        is_active: false,
        link: {
          name: 'agent.report-sms-summaries'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Satisfaction Surveys',
        is_active: false,
        link: {
          name: 'agent.report-satisfaction-surveys'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  }
]

export default AgentSideMenu
