const LeadSupervisorSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lead-supervisor.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Raw Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lead-supervisor.raw-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-database-2-line'
  },
  {
    title: 'Master Data',
    is_heading: false,
    is_active: false,
    link: {
      name: 'lead-supervisor.master-data'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-group-line'
  },
  {
    title: 'Visit Requests',
    is_active: false,
    link: {
      name: 'lead-supervisor.visit-requests'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-car-line'
  }
  // {
  //   title: 'Maintenances',
  //   name: 'sidebar.admin.maintenances',
  //   is_heading: false,
  //   is_active: false,
  //   link: '',
  //   class_name: '',
  //   is_icon_class: true,
  //   icon: 'ri-folder-settings-line',
  //   children: [
  //     {
  //       title: 'Agents',
  //       is_heading: false,
  //       is_active: false,
  //       link: {
  //         name: 'lead-supervisor.agents'
  //       },
  //       class_name: '',
  //       is_icon_class: true,
  //       icon: 'ri-contacts-line'
  //     }
  //   ]
  // }
]

export default LeadSupervisorSideMenu
